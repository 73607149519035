<template>
  <div style="height: calc(100% - 1px); width: 100%;" class="a_chat">
    <el-scrollbar  v-show="messageList.length > 0 || loading" v-loading="loading" ref="scroll" style="height: calc(100% - 190px);" wrap-style="overflow-x:hidden;">
      <div v-for="(item, index) in messageList" :key="index">
        <div style="width: 40px;display: inline-block;vertical-align: top;">
          <el-avatar v-if="!userAvatars[item.userId]" size="medium" icon="el-icon-user-solid"></el-avatar>
          <el-avatar v-else :src="userAvatars[item.userId]" size="medium"></el-avatar>
        </div>
        <div style="width: calc(100% - 45px); display: inline-block;margin-left: 5px;margin-top: 0px">
          <div style="text-align: left; padding-bottom: 5px" class="message-info">
            <div class="user">{{item.userName}}</div>
            <div class="date">{{item.formattedTime}}</div>
          </div>
          <div v-html="item.message" class="message"></div>
          <!--<i class="el-icon-edit" @click="click(item.id)" ></i>-->
          <hr>
        </div>
      </div>
    </el-scrollbar>
    <div v-show="messageList.length === 0 && !loading" style="height: calc(100% - 190px); width: 100%; background-color: #f4f4f5;display: flex;
    align-items: center;
    justify-content: center;">
      <div style="font-weight: 700;font-size: 13px;color: #909399;">{{ $locale.interface_editor.component_basic.chat.no_comments }}</div>
    </div>
    <quill-editor ref="input" :style="'min-height:100px; margin-bottom: 15px'" name="chat"
                  v-model="textarea"
                  :options="editorOptions"></quill-editor>
    <div>
      <el-button type="primary" size="small" style="display: inline-block;" @click="save">{{ $locale.main.button.ok }}
      </el-button>
      <el-button size="small" plain @click="cancel">{{ $locale.main.button.cancel }}</el-button>
      <el-link @click="loadData" type="info" class="update-date">{{ $locale.interface_editor.component_basic.chat.update_date }}: {{ updateDate | moment("DD.MM.Y H:mm:ss") }} <i class="el-icon-refresh"></i></el-link>
    </div>
    <slot></slot>
  </div>
</template>

<script>
import mixin from '../mixins'
import { quillEditor } from 'vue-quill-editor'
import 'quill/dist/quill.core.css'
import 'quill/dist/quill.snow.css'
import 'quill/dist/quill.bubble.css'

// API
import { APIClient } from '@/core/infrastructure/api/APIClient'
import { UserAPI } from '@/services/AccessEditor/infrastructure/api/UserAPI'

export default {
  name: 'a-chat',
  components: {
    quillEditor
  },
  inject: {
    getRegistryRecordId: { default: () => {} },
    forceUpdateSettingsPanel: { default: () => () => {} }
  },
  mixins: [mixin],
  props: {
    editorAlias: {
      type: String,
      description: 'alias'
    },
    outerXref: {
      type: String,
      description: 'outer_xref_id'
    },
    userField: {
      type: String,
      description: 'user_field_id'
    },
    dateField: {
      type: String,
      description: 'date_field_id'
    },
    messageField: {
      type: String,
      description: 'message_field_id'
    },
    placeholder: {
      type: String,
      description: 'placeholder'
    }
  },
  data () {
    return {
      editorOptions: {
        placeholder: this.placeholder,
        modules: {
          toolbar: [
            ['bold', 'italic', 'underline', 'strike'],
            ['blockquote', 'code-block'],
            [{ 'header': 1 }, { 'header': 2 }],
            [{ 'list': 'ordered' }, { 'list': 'bullet' }],
            [{ 'indent': '-1' }, { 'indent': '+1' }],
            [{ 'color': [] }, { 'background': [] }]
          ]
        }
      },
      textarea: '',
      messageList: [],
      registryId: null,
      linkId: null,
      loading: false,
      updateDate: new Date(),
      userAvatars: {}
    }
  },
  watch: {
    messageList () {
      this.$nextTick(() => {
        this.scrollBottom()
      })
    },
    editorAlias () {
      this.forceUpdateSettingsPanel()
    }
  },
  async mounted () {
    let respondToVisibility = function (element, callback) {
      let options = {
        root: document.documentElement
      }

      let observer = new IntersectionObserver((entries, observer) => {
        entries.forEach(entry => {
          callback(entry.intersectionRatio > 0)
        })
      }, options)

      observer.observe(element)
    }
    respondToVisibility(this.$el, (visible) => {
      if (visible) {
        setTimeout(() => this.scrollBottom(), 100)
      }
    })
    if (this.outerXref) {
      let xrefProperties = await this.$http.get(`${this.$config.api}/objecteditor/entities/${this.outerXref}`)
      xrefProperties = xrefProperties.data.data.properties
      if (!xrefProperties) {
        return false
      }
      let linkId = (xrefProperties.find(item => item.id === 'xref') || {}).value
      if (!linkId) {
        return false
      }
      this.linkId = linkId
      let objectProperties = await this.$http.get(`${this.$config.api}/objecteditor/entities/${linkId}`)
      objectProperties = objectProperties.data.data
      if (!objectProperties) {
        return false
      }
      this.registryId = objectProperties.object_id
    }

    if (
      !this.outerXref ||
      !this.userField ||
      !this.dateField ||
      !this.messageField ||
      !this.getRegistryRecordId()
    ) {
      return false
    }
    await this.loadData()
  },
  methods: {
    onChange ({ quill, html, text }) {
      this.textarea = html
    },
    async loadUserAvatar (userId) {
      if (!userId) {
        return null
      }
      if (typeof this.userAvatars[userId] === 'undefined') {
        this.$set(this.userAvatars, userId, null)
        // let userData = await this.$http.get(`${this.$config.api}/accesseditor/users/${userId}`)
        // let avatarId = userData.data.avatar_id
        let userData = []
        try {
          userData = await APIClient.shared.request(new UserAPI.GetUserById(userId))
        } catch (error) {
          console.log({ error })
        }
        let avatarId = userData[0].avatar_id
        if (!avatarId) {
          return null
        }
        let avatar = await this.$http.get(`${this.$config.api}/registryservice/files/${avatarId}`)
        this.$set(this.userAvatars, userId, this.$config.api + `/files/${this.getFilePath(avatar.data)}`)
      }

      return this.userAvatars[userId]
    },
    scrollBottom () {
      let scrollZone = this.$refs.scroll.$refs.wrap
      scrollZone.scrollTop = scrollZone.scrollHeight
    },
    async loadData () {
      if (
        !this.outerXref ||
                    !this.userField ||
                    !this.dateField ||
                    !this.messageField ||
                    !this.registryId ||
                    !this.linkId ||
                    !this.getRegistryRecordId()
      ) {
        return false
      }
      // this.loading = true
      let data = await this.$http.get(`${this.$config.api}/registryservice/registry/${this.registryId}/data?limit=1000&filter[0]=attr_${this.linkId}_,eqx,${this.getRegistryRecordId()}`)
      this.messageList = []
      this.addMessages(data.data.data)
      this.updateDate = new Date()
      // this.loading = false
      this.scrollBottom()
    },
    addMessages (messages = []) {
      messages.forEach((item) => {
        let message = this.prepareMessage(item)
        this.messageList.push(message)
      })
      this.messageList = this.messageList.sort((a, b) => new Date(a.time) - new Date(b.time))
    },
    prepareMessage (item) {
      let message = {}
      message.id = item.id
      message.userName = JSON.parse(item[`attr_${this.userField}_`])[0].name
      message.userId = JSON.parse(item[`attr_${this.userField}_`])[0].id
      message.time = item[`attr_${this.dateField}_`]
      message.formattedTime = this.formatDate(item[`attr_${this.dateField}_`])
      message.message = item[`attr_${this.messageField}_`]
      this.loadUserAvatar(message.userId)

      return message
    },
    async save () {
      if (
        !this.userField ||
        !this.dateField ||
        !this.messageField ||
        !this.registryId ||
        !this.linkId ||
        !this.getRegistryRecordId()
      ) {
        return false
      }

      if (this.textarea && this.$store.getters['Authorization/userId']) {
        let message = {}
        message[`attr_${this.linkId}_`] = this.getRegistryRecordId()
        message[`attr_${this.userField}_`] = this.$store.getters['Authorization/userId']
        message[`attr_${this.dateField}_`] = this.$moment(new Date()).format('Y-MM-DD H:mm:ss')
        message[`attr_${this.messageField}_`] = this.textarea
        message[`object_id`] = this.registryId

        let answer = await this.$http.post(`${this.$config.api}/registryservice/registry/${this.registryId}/records`, message)
        let messageId = answer.data.id

        if (messageId) {
          let data = await this.$http.get(`${this.$config.api}/registryservice/registry/${this.registryId}/data/${messageId}`)
          this.messageList.push(this.prepareMessage(data.data))
        }

        this.textarea = ''
      }
    },
    cancel () {
      console.log(this.$refs.input)
      this.textarea = ''
    },
    formatDate (time) {
      const newTime = Math.floor((new Date() - new Date(time)) / 1000)
      if (newTime === 0) {
        return 'сейчас'
      } else if (newTime < 60) { // 'секунды'
        const timeTitle = Math.floor(newTime)
        switch (timeTitle) {
          case (1):
            return timeTitle + ' секунду назад'
          case (2):
          case (3):
          case (4):
            return timeTitle + ' секунды назад'
          default:
            return timeTitle + ' секунд назад'
        }
      } else if (newTime > 60 && newTime < 3600) { // 'минуты'
        const timeTitle = Math.floor(newTime / 60)
        switch (timeTitle) {
          case (1):
            return timeTitle + ' минуту назад'
          case (2):
          case (3):
          case (4):
            return timeTitle + ' минуты назад'
          default:
            return timeTitle + ' минут назад'
        }
      } else if (newTime > 3600 && newTime < 86400) { // 'часы'
        const timeTitle = Math.floor(newTime / 3600)
        switch (timeTitle) {
          case (1):
            return timeTitle + ' час назад'
          case (2):
          case (3):
          case (4):
            return timeTitle + ' часа назад'
          default:
            return timeTitle + ' часов назад'
        }
      } else if (newTime > 86400 && newTime < 604800) { // 'дни'
        const timeTitle = Math.floor(newTime / 86400)
        switch (timeTitle) {
          case (1):
            return timeTitle + ' день назад'
          case (2):
          case (3):
          case (4):
            return timeTitle + ' дня назад'
          default:
            return timeTitle + ' дней назад'
        }
      } else if (newTime > 604800) { // 'вывод даты'
        const fullDate = new Date(time)
        let date = fullDate.getDate()
        let month = fullDate.getMonth() + 1
        if (date < 10) {
          date = '0' + date
        }
        if (month < 10) {
          month = '0' + month
        }
        return `${date}.${month}.${fullDate.getFullYear()}`
      }
    }
  }
}
</script>

<style lang="scss">
  .message-info .user, .message-info .date {
    display: inline-block;
  }

  .message-info .user {
    font-weight: bold;
    color: #1f2f3d;
  }

  .message-info .date {
    float: right;
    color: #606266;
    font-size: 13px;
  }

  .message {
    text-align: left;
    padding: 5px;
    font-size: 14px;
  }
  .update-date {
    float: right;
    color: #606266;
    font-size: 13px;
    margin: 8px;
  }
  .a_chat .quill-editor .ql-container.ql-snow {
    height: 50px !important;
  }
</style>
